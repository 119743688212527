import { type EPTemplate } from './types';
import { regexpParts } from './utils/regex-utils';

export type {
	EPTemplate,
	EPTemplatePart,
	EPTemplatePartText,
	EPTemplatePartEscapedVariable,
	EPTemplatePartUnescapedVariable,
} from './types';

/**
 * This is regular expression to parse double and triple bracket variables.
 * We should replace this with an imperative parser or a library if we want to
 * add more functionality, but this is simple enough as it is.
 *
 * The regular expression is:
 *
 * # Try to match triple bracket variables in a capture group:
 * (
 *   {{{ # Start matching on leading "{{{"
 *     (
 *       # Match on 1+ inner characters
 *       (
 *         # Do negative look-ahead for closing brackets '}}}'.
 *         # Then match on any character. What this means is, match on any
 *         # character that isn't a bracket followed by two other brackets.
 *         # So, first check if we're not at the end of the tag, otherwise
 *         # match.
 *         # Negative look-ahead won't collect the character, just fail if it's
 *         # the triple bracket sequence.
 *         (?!}}}).
 *       )+
 *     ) # Finish `UNESCAPED_GROUP_CONTENTS`, which is our UNESCAPED variable
 *       # name capture group
 *   }}}
 * )
 *
 * | # OR try matching the double bracket variables
 *
 * # The logic is the same, throughout
 * (
 *   {{
 *     (
 *       (
 *         (?!}}).
 *       )+
 *     ) # Finish `ESCAPED_GROUP_CONTENTS`, which is our ESCAPED variable name
 *       # capture group
 *   }}
 * )
 *
 * See https://regex101.com/r/Eo26HD/1 too.
 *
 * Group indexes are named bellow.
 */
const PARSE_VARIABLES_REGEXP = /({{{(((?!}}}).)+)}}})|({{(((?!}}).)+)}})/g;
const UNESCAPED_MATCH = 1;
const UNESCAPED_GROUP_CONTENTS = 2;
const ESCAPED_GROUP_CONTENTS = 5;

/**
 * Parses an EPTemplate object from a template string.
 *
 * The parsed template is essentially a list of the template parts, which are
 * either:
 *
 * - A plain text part
 * - An escaped variable (e.g. {{variableName}}
 * - An unescaped variable (e.g. {{{variableName}}}
 *
 * @param {String} template
 * @return {EPTemplate}
 */
export default function parseTemplate(template: string): EPTemplate {
	const parts = regexpParts(template, PARSE_VARIABLES_REGEXP);

	return {
		parts: parts.map((regexpPart) => {
			if (regexpPart.type === 'text') {
				return regexpPart;
			}

			if (regexpPart.match[UNESCAPED_MATCH]) {
				return {
					type: 'unescaped',
					variableName: regexpPart.match[UNESCAPED_GROUP_CONTENTS] || '',
				};
			}

			return {
				type: 'escaped',
				variableName: regexpPart.match[ESCAPED_GROUP_CONTENTS] || '',
			};
		}),
	};
}
