import parseTemplate from '../parser';
import { replaceForHtml, replaceForUrl } from '../runner';
import {
	REPLACEMENT_ERROR,
	REPLACEMENT_SUCCESS,
	type ReplacementResult,
	type Variables,
} from '../runner/types';

import { getRequiredVariablesFromTemplate } from './utils';

export { REPLACEMENT_SUCCESS, REPLACEMENT_ERROR };

/**
 * Parse a template string and return the variables it depends on.
 *
 * @param {String} template
 * @return {Array<String>}
 */
export function getRequiredVariables(template: string): string[] {
	const parsedTemplate = parseTemplate(template);

	return getRequiredVariablesFromTemplate(parsedTemplate);
}

/**
 * Parse run the template for URLs
 *
 * @param {String} template
 * @param {Object} variables
 * @return {ReplacementResult}
 */
export function runTemplateForUrl(template: string, variables: Variables): ReplacementResult {
	const epTemplate = parseTemplate(template);

	return replaceForUrl(epTemplate, variables);
}

/**
 * Parse run the template for HTML
 *
 * @param {String} template
 * @param {Object} variables
 * @return {ReplacementResult}
 */
export function runTemplateForHtml(template: string, variables: Variables): ReplacementResult {
	const epTemplate = parseTemplate(template);

	return replaceForHtml(epTemplate, variables);
}
