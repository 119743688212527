export type Variables = { [key: string]: string };

export const REPLACEMENT_SUCCESS: 'success' = 'success';
export const REPLACEMENT_ERROR: 'error' = 'error';

export interface ReplacementSuccess {
	type: typeof REPLACEMENT_SUCCESS;
	result: string;
}

export interface ReplacementError {
	type: typeof REPLACEMENT_ERROR;
	errorType: 'missing-variables-error';
	missingVariables: string[];
}

export type ReplacementResult = ReplacementSuccess | ReplacementError;
