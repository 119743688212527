import React, { PureComponent } from 'react';

type Props = {
	html: string;
};

// eslint-disable-next-line @repo/internal/react/no-class-components
export default class HtmlContent extends PureComponent<Props> {
	render() {
		const { html } = this.props;

		return <div dangerouslySetInnerHTML={{ __html: html }} />;
	}
}
