import { type ButtonAction } from '../../common/types/button';
import { REPLACEMENT_ERROR, runTemplateForHtml, runTemplateForUrl } from '../engagement-templates';
import { type Variables } from '../runner/types';

export function rebuildButtonAction(replacedUrl: string, action: ButtonAction): ButtonAction {
	return {
		...action,
		data: action.data && {
			...action.data,
			url: replacedUrl,
		},
	};
}

export function runTemplateOnInputs(
	variables: Variables,
	messageInputs: Record<string, any>,
): Record<string, any> | null {
	const keys = Object.keys(messageInputs);
	const output: Record<string, any> = {};

	for (let i = 0; i < keys.length; i++) {
		const key = keys[i];
		const input = messageInputs[key];

		if (input) {
			const isUrl = key === 'primaryActionUrl' || key === 'secondaryActionUrl';
			const replacedInput = isUrl
				? runTemplateForUrl(input, variables)
				: runTemplateForHtml(input, variables);

			if (replacedInput.type === REPLACEMENT_ERROR) {
				return null;
			}

			output[key] = replacedInput.result;
		}
	}

	return output;
}
