import React, { Component, type ComponentType } from 'react';

import { withAnalyticsEvents } from '@atlaskit/analytics-next';

import { type CreateAnalyticsEvent } from '../../common/types/analytics';

type Props = {
	createAnalyticsEvent: CreateAnalyticsEvent;
	messageId: string;
	variationId: string;
	componentId: number;
	componentType?: string;
	isLast?: boolean;
};

export default (InnerComponent: ComponentType<any>): ComponentType<any> => {
	// eslint-disable-next-line @repo/internal/react/no-class-components
	class EngagementEventsWrapper extends Component<Props> {
		UNSAFE_componentWillMount() {
			this.fireEvent('shown');
		}

		componentWillUnmount() {
			this.fireEvent('hidden');
		}

		fireClickEvent = (clickType: string) => {
			this.fireEvent('click', { clickType });
		};

		fireEvent(action: string, properties: Record<string, any> = {}) {
			const { createAnalyticsEvent, messageId, variationId, componentId, componentType, isLast } =
				this.props;
			const analyticsEvent = createAnalyticsEvent({ action, ...properties });
			analyticsEvent.update({
				ekMessageId: messageId,
				ekVariationId: variationId,
				ekComponentId: componentId,
				ekComponentType: componentType,
				ekIsLast: isLast,
			});
			analyticsEvent.fire('engagement');
		}

		render() {
			return <InnerComponent {...this.props} fireClickEvent={this.fireClickEvent} />;
		}
	}

	return withAnalyticsEvents()(EngagementEventsWrapper);
};
