/**
 * Removes duplicate strings from an array.
 */
export function uniqStrings(strings: string[]): string[] {
	const ourSet: { [key: string]: null } = {};
	for (let i = 0; i < strings.length; i++) {
		ourSet[strings[i]] = null;
	}

	return Object.keys(ourSet);
}
