import React, { Component } from 'react';

import noop from 'lodash/noop';

import { Spotlight as AkSpotlight } from '@atlaskit/onboarding';
import { type Actions } from '@atlaskit/onboarding/types';

import { type FireClickEvent } from '../../common/types/analytics';
import { type ButtonAction, buttonActionTypes } from '../../common/types/button';
import HtmlContent from '../../controllers/utils/html-content';

type ClickHandler = () => void;

type dialogPlacement =
	| 'top left'
	| 'top center'
	| 'top right'
	| 'right top'
	| 'right middle'
	| 'right bottom'
	| 'bottom left'
	| 'bottom center'
	| 'bottom right'
	| 'left top'
	| 'left middle'
	| 'left bottom';

type Props = {
	body?: string;
	fireClickEvent?: FireClickEvent;
	dialogPlacement?: dialogPlacement;
	dialogWidth?: number;
	title?: string;
	image?: string;
	primaryAction?: ButtonAction;
	primaryText?: string;
	pulse?: boolean;
	secondaryAction?: ButtonAction;
	secondaryText?: string;
	target?: string;
	targetBgColor?: string;
	targetRadius?: number;
	engagementId?: string;
	isShown?: boolean;
};

// eslint-disable-next-line @repo/internal/react/no-class-components
export default class EngagementSpotlight extends Component<Props> {
	static defaultProps = {
		fireClickEvent: noop,
	};

	getButtonClickHandler(buttonType: string, action: ButtonAction): ClickHandler {
		const { fireClickEvent } = this.props;

		switch (action.type) {
			case buttonActionTypes.LINK:
				return () => {
					fireClickEvent!(`${buttonType}`);

					window.open(action.data!.url, action.data!.target);
				};
			default:
				return () => {
					fireClickEvent!(`${buttonType}`);
				};
		}
	}

	getButtonActions(): Actions {
		const { primaryAction, primaryText, secondaryAction, secondaryText } = this.props;
		const actions: Actions = [];

		if (secondaryAction && secondaryText) {
			actions.push({
				onClick: this.getButtonClickHandler('secondary', secondaryAction),
				text: secondaryText,
				appearance: secondaryAction.appearance,
			});
		}

		if (primaryAction && primaryText) {
			actions.push({
				onClick: this.getButtonClickHandler('primary', primaryAction),
				text: primaryText,
				appearance: primaryAction.appearance,
				autoFocus: true,
			});
		}

		return actions;
	}

	render() {
		const {
			body,
			dialogPlacement,
			dialogWidth,
			title,
			image,
			pulse,
			target,
			targetBgColor,
			targetRadius,
		} = this.props;

		return (
			<AkSpotlight
				actions={this.getButtonActions()}
				dialogPlacement={dialogPlacement}
				dialogWidth={dialogWidth}
				heading={title}
				image={image}
				pulse={pulse}
				target={target}
				targetBgColor={targetBgColor}
				targetRadius={targetRadius}
			>
				<HtmlContent html={body!} />
			</AkSpotlight>
		);
	}
}
