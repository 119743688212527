import { type Appearance } from '@atlaskit/button';

export const buttonActionTypes = {
	LINK: 'link',
	DISMISS: 'dismiss',
};

export type ButtonAction = {
	data?: {
		target: '_blank' | '_parent' | '_self' | '_top';
		url: string;
	};
	type: string;
	appearance: Appearance | undefined;
};
