import { type EPTemplate, type EPTemplatePart, type EPTemplatePartVariable } from '../parser/types';
import { uniqStrings } from '../runner/utils';

/**
 * Filter only variable types and fix flow types.
 *
 * @param {EPTemplate} template
 * @returns {EPTemplatePartVariable}
 */
export function getTemplateVariableParts(template: EPTemplate): EPTemplatePartVariable[] {
	const variableParts: any = template.parts.filter(
		(part: EPTemplatePart) => part.type === 'escaped' || part.type === 'unescaped',
	);

	return variableParts;
}

/**
 * Return the variables a template depends on.
 *
 * @param {EPTemplate} template
 * @return {Array<String>}
 */
export function getRequiredVariablesFromTemplate(template: EPTemplate): string[] {
	const variableParts = getTemplateVariableParts(template);
	const variableNames = variableParts.map((part) => part.variableName);

	return uniqStrings(variableNames);
}
