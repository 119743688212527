import React, { Component, type ComponentType } from 'react';

import { type $WithVariables, type VariableProps } from './types';
import { rebuildButtonAction, runTemplateOnInputs } from './utils';

/**
 * HOC that'll run template replacement on `MessageProps` fields of spotlight
 * messages and render the wrapped component.
 *
 * If any of the replacements fail with missing variables the component isn't
 * rendered and null is returned.
 *
 * The returned wrapped component accepts a `variables=` property which should
 * be a map of key to string value.
 *
 * This property isn't forwarded to the wrapped component
 *
 * @param TargetComponent<Props>
 * @returns {Component<Props & VariableProps>}
 */
export default function withVariables<Props>(
	TargetComponent: ComponentType<Props | Record<string, any>>,
): ComponentType<$WithVariables<Props>> {
	// eslint-disable-next-line @repo/internal/react/no-class-components
	class WithVariables extends Component<Props & VariableProps> {
		static displayName = `${
			TargetComponent.displayName || TargetComponent.name || ''
		}WithEPVariables`;

		render() {
			const props = runMessagePropsReplacement<Props>(this.props);

			if (!props) {
				return null;
			}

			return <TargetComponent {...props} />;
		}
	}

	return WithVariables;
}

/**
 * Runs replacement over spotlight message properties.
 * Returns null if replacement fails.
 */
function runMessagePropsReplacement<Props>(
	props: Props & VariableProps,
): Record<string, any> | Props | null {
	const { variables, ...otherProps } = props;

	const { title, body, primaryText, primaryAction, secondaryText, secondaryAction } = props;
	const inputs = {
		title,
		body,
		primaryText,
		primaryActionUrl: primaryAction && primaryAction.data && primaryAction.data.url,
		secondaryText,
		secondaryActionUrl: secondaryAction && secondaryAction.data && secondaryAction.data.url,
	};
	const replacedInputs = runTemplateOnInputs(variables || {}, inputs);

	if (!replacedInputs) {
		return null;
	}

	const outputProps: Record<string, any> = {
		...otherProps,
		title: replacedInputs.title,
		body: replacedInputs.body,
		primaryText: replacedInputs.primaryText,
		secondaryText: replacedInputs.secondaryText,
	};

	if (primaryAction) {
		outputProps.primaryAction = rebuildButtonAction(replacedInputs.primaryActionUrl, primaryAction);
	}

	if (secondaryAction) {
		outputProps.secondaryAction = rebuildButtonAction(
			replacedInputs.secondaryActionUrl,
			secondaryAction,
		);
	}

	return outputProps;
}
