import { type RegExpPart } from './types';

/**
 * Execs the RegExp on the input string collecting all it's matches, then return
 * an Array of either text or match objects.
 *
 * @param {String} input
 * @param {RegExp} regexp
 * @return {Array<RegExpPart>} Text or match objects
 */
export function regexpParts(input: string, regexp: RegExp): RegExpPart[] {
	const allMatches = execAll(input, regexp);
	const state = allMatches.reduce(
		(memo, matchResult) => {
			const leadingIndex = matchResult.index || 0;
			const leading = input.slice(memo.currentPosition, leadingIndex);

			if (leading) {
				memo.result.push({
					type: 'text',
					text: leading,
				});
			}

			memo.result.push({
				type: 'match',
				match: matchResult,
			});

			return {
				currentPosition: leadingIndex + matchSize(matchResult),
				result: memo.result,
			};
		},
		{
			currentPosition: 0,
			result: [],
		},
	);

	const trailing = input.slice(state.currentPosition, input.length);
	if (trailing) {
		state.result.push({
			type: 'text',
			text: trailing,
		});
	}

	return state.result;
}

/**
 * Executes a RegExp onto an input string until it can't match and return the
 * collected matches.
 *
 * @param {String} input
 * @param {RegExp} regexp
 * @return {Array<RegExp$matchResult>} All the matches
 */
// @ts-ignore
export function execAll(input: string, regexp: RegExp): any[] {
	const result: Array<RegExpExecArray> = [];

	for (let m = regexp.exec(input); m; m = regexp.exec(input)) {
		result.push(m);
	}

	return result;
}

/**
 * Given a single RegExp match object, return the length of the text it matches.
 *
 * @param {RegExp$matchResult} matchResult
 * @return {Number} The length of the text matched
 */
// @ts-ignore
export function matchSize(matchResult: any): number {
	return matchResult[0] ? matchResult[0].length : 0;
}
