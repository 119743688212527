import React from 'react';

import { SpotlightTransition } from '@atlaskit/onboarding';

import withVariables from '../../controllers/engagement-template-with-variables';
import { type VariableProps } from '../../controllers/engagement-template-with-variables/types';
import withEvents from '../../controllers/engagement-with-events';
import withMessage, {
	type Props as WithMessageProps,
} from '../../controllers/engagement-with-message';

import Spotlight from './main';

const SpotlightWithVariables = withVariables(Spotlight);

const SpotlightWithEvents = withEvents(SpotlightWithVariables);

export { Spotlight, SpotlightWithEvents };

const SpotlightWithMessage = withMessage(SpotlightWithEvents, 'spotlight');

export default function EngagementSpotlight(props: WithMessageProps & VariableProps) {
	return (
		<SpotlightTransition>
			<SpotlightWithMessage {...props} />
		</SpotlightTransition>
	);
}

// duplicate the export as a named one. That way, the export name in
// this entrypoint (defined in "af:exports") matches the name that is
// used in the main (src/index.ts) entrypoint.
export { EngagementSpotlight };
