/**
 * Escapes an HTML string.
 *
 * @param {String} input
 * @return {String}
 */
export default function escapeHtml(input: string): string {
	return input
		.replace(/</g, '&lt;')
		.replace(/>/g, '&gt;')
		.replace(/&/g, '&amp;')
		.replace(/"/g, '&quot;')
		.replace(/'/g, '&#39');
}
