import { getRequiredVariablesFromTemplate } from '../engagement-templates/utils';
import { type EPTemplate, type EPTemplatePart } from '../parser';

import { type ReplacementResult, type Variables } from './types';
import escapeHtml from './utils/escape-html';

/**
 * Runs variable validation and if successful calls the provided function and
 * wraps it in a result object, otherwise returns a failure.
 */
function withVariableValidation(
	template: EPTemplate,
	variables: Variables,
	fn: (template: EPTemplate, variables: Variables) => string,
): ReplacementResult {
	const requiredKeys = getRequiredVariablesFromTemplate(template);
	const missingKeys = requiredKeys.filter((variableName) => !variables[variableName]);

	if (missingKeys.length) {
		return {
			type: 'error',
			errorType: 'missing-variables-error',
			missingVariables: missingKeys,
		};
	}

	return { type: 'success', result: fn(template, variables) };
}

/**
 * Replaces variables onto a template, using the provided escaping function.
 */
function rawReplaceVariables(
	template: EPTemplate,
	variables: Variables,
	escapeFn: (input: string) => string,
): string {
	return template.parts
		.map((templatePart: EPTemplatePart) => {
			if (templatePart.type === 'text') {
				return templatePart.text;
			}
			if (templatePart.type === 'escaped') {
				return escapeFn(variables[templatePart.variableName]);
			}

			return variables[templatePart.variableName];
		})
		.join('');
}

/**
 * Without validation, runs a template with URI escaping.
 */
function rawReplaceVariablesForUrl(template: EPTemplate, variables: Variables): string {
	return rawReplaceVariables(template, variables, encodeURIComponent);
}

/**
 * Without validation, runs a template with HTML escaping.
 */
function rawReplaceVariablesForHtml(template: EPTemplate, variables: Variables): string {
	return rawReplaceVariables(template, variables, escapeHtml);
}

/**
 * Validates variables and runs a template with URI escaping.
 */
export function replaceForUrl(template: EPTemplate, variables: Variables) {
	return withVariableValidation(template, variables, rawReplaceVariablesForUrl);
}

/**
 * Validates variables and runs a template with HTML escaping.
 */
export function replaceForHtml(template: EPTemplate, variables: Variables) {
	return withVariableValidation(template, variables, rawReplaceVariablesForHtml);
}
